import React, { useState, useEffect } from 'react';

function PracticeGenerator(props) {
  console.log("Start practice generator!");
  
  useEffect(() => { document.title = props.title }, [])
  const [displayHtml, setDisplayHtml] = useState("");

  //List of activities -----------
  var exercises = [
    "8 exercises ",
    "Elbow Strikes ",
    "Combination Kicks ",
    "Combination Punches ",
    "Advanced Elbow strikes(1-4)",
  ];
  var katas = [
    "Kata 1:  Troll Dance ",
    "Kata 2: breathe. forward. punch. elbow. back. done. ",
    "Kata 3: cheerleader ",
    "Kata 4: breathe back. one step 1. elbow. punch. ",
    "Kata 5: horizontal back and forth. ",
    "Kata 6-1: h form " +
    "Kata 6-2: h form + punch. high block " +
    "Kata 6-3: h form + block. k k poke. high block ",
    "Kata 6-4: limp lock. cat stance. High block  . p p k down " +
    "Kata 6-5: flower block " +
    "Kata 6-6:  flower. kick chain p. poke  ",
    "Kata 6-7: down break. chop. kick kick block. flower up. " +
    "Kata 6-8: cheerleader elbow. flower up " +
    "Kata 6-9:  flower. palm. kick. monk down.  KICK combo up. ",
    "Kata 7: hashishi ",
    "Kata 8: troll back and forth ",
    "Kata 10: acrobat ",
    "Kata 11: left-right. (complicated) ",
    "Kata 12 ",
  ];

  var oneSteps = [
    "One Step 1:  ",
    "One Step 2:  ",
    "One Step 3:  ",
    "One Step 4:  ",
    "One Step 5:  ",
    "One Step 6:  ",
    "One Step 7:  ",
    "One Step 8:  ",
    "One Step 9:  ",
    "One Step 10: bearhug. groin. elbow. snake. ",
    "One Step 11: on hand. elbow chop kick ",
    "One Step 12: 2 hand. check hold. kick chop down ",
    "One Step 13: nelson. tense up drop. elbow grab flip hit on ground ",
    "One Step 14: choke. elbow scoop ",
    "One Step 15: choke. elbow. leg in between. hip throw ",
    "One Step 16: choke. grab hand. extend. reap. ",
    "Advanced One Step 1 ",
    "Advanced One Step 2 ",
    "Advanced One Step 3 ",
    "Advanced One Step 4 ",
    "Advanced One Step 5 ",
  ];

  var judoRolls = [
    "Judo Roll 1: Right roll ",
    "Judo Roll 2: Left roll ",
    "Judo Roll 3: Double roll ",
    "Judo Roll 4: Kip with slide ",
    "Judo Roll 5: Kip ",
    "Judo Roll 6: Right roll. snap kick. punch ",
    "Judo Roll 7: Right roll. snap kick. punch. cross back. kick. cover ",
    "Judo Roll 8: Right roll. block. spear. kick ",
    "Judo Roll 9: Left roll. back fist to groin. knuckles to face. sweep ",
    "Judo Roll 10: Double open hand block ",
    "Judo Roll 11: Block. right hook punch. left to kidney. spin sweep ",
    "Judo Roll 12: Right roll. clock. kick kick cross ",
    "Judo Roll 13: Left roll. first punch - kick. second punch - block throat.throw ",
    "Judo Roll 14: Right roll. block arm. chop to neck. right reap throw ",
    "Judo Roll 15: Left roll. aikido throw ",
    "Judo Roll 16: Left roll. 'clothes line' right chop to neck. u cup strike to throat. right reapthrow ",
    "Judo Roll 17: Left roll. double arm bar. scotts move ",
    "Judo Roll 18: Left roll. squat. jump snap kick ",
    "Judo Roll 19: Right roll. grab. knee to stomach. right reap throw ",
    "Judo Roll 20: Left roll. chop to throat. chicken wing. right throw reap ",
    "Judo Roll 21: Left roll. left block. right chop chest. forearm to mid. left hook kick ",
    "Judo Roll 22: Left roll. left block. right chop chest. right elbow to kidney " +
    "spin left elbow to mid. @ back now. right chop to neck. left to kidney " +
    "grab right shoulder. pull back left sweep kick the right leg ",
  ];

  var weaponForms = [
    "Bo: Single Weave",
    "Bo: Double Weave",
    "Bo: H-Form",
    "Bo: Long-Short ",
    "Bo: Tokimine",
    "Kama",
    "Katana Form",
    "Katana Draws (ALL)",
  ];

  function generateSchedule() {
    var practiceHtml = "";

    practiceHtml += "    <br>   <br>    <br>";

    practiceHtml += generateSection("Exercises", exercises, 2);
    practiceHtml += generateSection("Katas", katas, 3);
    practiceHtml += generateSection("One Steps", oneSteps, 3);
    practiceHtml += generateSection("Judo Rolls", judoRolls, 3);
    practiceHtml += generateSection("Weapon Forms", weaponForms, 2);

    setDisplayHtml(practiceHtml);
  }

  function generateSection(title, listObj, numToGenerate) {
    var selectedPreviously = [];
    var i = 0;
    var stop = numToGenerate;
    var tempHtml = "<h3>" + title + " </h3>";
    while (i < stop) {
      var rand = getRandomBetweenMinAndMax(0, listObj.length);

      if (!selectedPreviously.includes(rand)) {
        selectedPreviously.push(rand);
        tempHtml += listObj[rand] + "<br>";
        i++;
      }
    }
    tempHtml += "<br>";
    return tempHtml;
  }

  function getRandomBetweenMinAndMax(min, max) {
    return Math.floor(Math.random() * (max-min) + min);
  }

  return (
    <div className="main-content-padding-top main-content-padding-x">
      <div className="container">
        <div className="row">
          <div className="col">
            <h1>Kajukenbo Practice</h1>
            <br />
            <button className=" btn btn-gen-schedule btn-primary" onClick={() => generateSchedule()} >Generate</button>
            <span className="content-practice container-fluid" dangerouslySetInnerHTML={{ __html: displayHtml }} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default PracticeGenerator;